<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <!-- Table Container Card -->

    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_blackList") }}
            </b-card-title>
            <!-- <b-card-sub-title class="mt-1">
              {{ lang("t_blackList") }}
            </b-card-sub-title> -->
          </b-card-header>

          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-on:keydown.enter="get_records_by_phone"
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>

              <b-button
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
                <b-icon icon="funnel"></b-icon>
              </b-button>
              <b-dropdown
                dropleft
                boundary="viewport"
                size="sm"
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                  @click="
                    modal_excel = true;
                    isEdit = false;
                    reset_record();
                  "
                >
                  <b-icon icon="upload"></b-icon>
                  <span class="align-middle ml-50">{{
                    lang("t_importRecords")
                  }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-card-text>
            <b-table
              small
              style="max-height: 100vh"
              hover
              :fields="tableColumns"
              :items="records"
              responsive
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="records.length == 0"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>

              <template #cell(is_inbound)="data">
                <feather-icon
                  :icon="data.item.is_inbound ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(is_outbound)="data">
                <feather-icon
                  :icon="data.item.is_outbound ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  boundary="viewport"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="updateSelected(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    records.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="totalUsers"
                first-number
                last-number
                @input="get_records"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_record"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="number"
            rules="required"
          >
            <b-form-group
              :description="lang('t_youCanAddCommaBetweenPhoneToAddMore')"
              :label="lang('t_number')"
              label-for="number"
            >
              <b-form-input
                id="number"
                v-model="currentData.number"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Surname -->
          <b-form-group :label="lang('t_description')" label-for="description">
            <b-form-textarea
              v-model="currentData.description"
              id="description"
              :placeholder="lang('t_description')"
              rows="3"
            >
            </b-form-textarea>
          </b-form-group>

          <b-form-checkbox
            inline
            v-model="currentData.is_inbound"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <!-- <i class="vs-icon feather icon-check" /> -->
                {{ lang("t_inbound") }}
              </span>
            </span>
          </b-form-checkbox>
          <b-form-checkbox
            inline
            v-model="currentData.is_outbound"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <!-- <i class="vs-icon feather icon-check" /> -->
                {{ lang("t_outbound") }}
              </span>
            </span>
          </b-form-checkbox>

          <!-- Form Actions
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{lang('t_add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
             {{lang('t_cancel')}}
            </b-button>
          </div>
           -->
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      @ok="read_data"
      v-model="modal_excel"
      :title="lang('t_importRecords')"
      centered
    >
      <b-row align-h="end">
        <b-button
          target="_blank"
          href="template_examples/voyce_blacklist_import.xlsx"
          :exact="true"
          size="sm"
          variant="flat-success"
          >{{ lang("t_exampleFile") }}</b-button
        >
      </b-row>
      <b-form-group :label="lang('t_file')" label-for="file">
        <b-form-file
          :browse-text="lang('t_file')"
          v-model="excelData.files"
          :placeholder="lang('t_uploadFileOnClick')"
          :drop-placeholder="lang('t_uploadFile')"
          no-drop
          required
          accept=".xlsx, .xls"
        />
      </b-form-group>

      <b-form-group :label="lang('t_description')" label-for="description">
        <b-form-textarea
          v-model="excelData.description"
          id="description"
          :placeholder="lang('t_description')"
          rows="3"
        >
        </b-form-textarea>
      </b-form-group>

      <b-form-checkbox
        inline
        v-model="excelData.is_inbound"
        class="custom-control-primary"
      >
        <span class="vs-checkbox">
          <span class="vs-checkbox--check">
            <!-- <i class="vs-icon feather icon-check" /> -->
            {{ lang("t_inbound") }}
          </span>
        </span>
      </b-form-checkbox>
      <b-form-checkbox
        inline
        v-model="excelData.is_outbound"
        class="custom-control-primary"
      >
        <span class="vs-checkbox">
          <span class="vs-checkbox--check">
            <!-- <i class="vs-icon feather icon-check" /> -->
            {{ lang("t_outbound") }}
          </span>
        </span>
      </b-form-checkbox>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BFormTextarea,
  BSpinner,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  BCardText,
  BFormFile
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // UsersListFilters,
    BFormFile,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormTextarea,
    vSelect,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BCardText,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      isEdit: false,
      modal_excel: false,
      currentData: {
        number: "",
        is_inbound: true,
        is_outbound: true,
        description: "",
      },
      excelData: {
        files: null,
        is_inbound: true,
        is_outbound: true,
        description: "",
      },
      searchQuery: "",
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
      records: [],
      tableColumns: [
        { key: "number", label: globalThis._lang("t_number"), sortable: true },
        {
          key: "is_inbound",
          label: globalThis._lang("t_inbound"),
          sortable: true,
        },
        {
          key: "is_outbound",
          label: globalThis._lang("t_outbound"),
          sortable: true,
        },
        {
          key: "description",
          label: globalThis._lang("t_descriptions"),
          sortable: true,
        },
        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: { width: '5vh' }

        },
      ],
    };
  },
  mounted: function () {
    this.get_records();
  },
  methods: {
    async read_data() {
      this.progress = true;
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = globalThis.XLSX.read(bstr, { type: "binary" });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = globalThis.XLSX.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
        });
        var _data = this.xlsxToJson(data);

        for (const item of _data) {
          this.currentData = {
            number: item["Telefon"] ?? '',
            is_inbound: this.excelData.is_inbound,
            is_outbound: this.excelData.is_outbound,
            description: this.excelData.description,
          };
          this.add_record();
        }
        this.progress = false;
        this.modal_excel = false;
      };
      reader.readAsBinaryString(this.excelData.files);
    },

    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },

    get_records: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.get(
          `/optmgr/v1/BlackList/${this.currentPage}/${this.perPage}/${this.searchQuery}`
        )
      ).data;
      console.log("resp", response);
      this.records = response.result;
      this.totalUsers = response.count;
      this.is_progress = false;
    },
    get_records_by_phone: async function () {
      if (this.searchQuery == "") {
        this.get_records();
        return;
      }
      this.is_progress = true;

      var response = (
        await this.$http_in.get(`/optmgr/v1/BlackList/${this.searchQuery}`)
      ).data;
      console.log("resp", response);
      this.records = response.result;
      this.totalUsers = response.count;
      this.is_progress = false;
    },
    check_data() {
      if (this.currentData.number == "") {
        return true;
      }
      return false;
    },

    add_record: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      this.is_progress = true;

      var response;
      if (!this.isEdit) {
        if (this.currentData.number.includes(',')) {
          for (const _number of this.currentData.number.split(',')) {
            let obj = {
              ...this.currentData,
              number: _number.split(' ').join('')
            }
            response = (
              await this.$http_in.post(`/optmgr/v1/BlackList`, {
                data: JSON.stringify(obj),
              })
            ).data;
          }
        } else {
          response = (
            await this.$http_in.post(`/optmgr/v1/BlackList`, {
              data: JSON.stringify(this.currentData),
            })
          ).data;
        }

      } else {
        response = (
          await this.$http_in.put(`/optmgr/v1/BlackList`, {
            data: JSON.stringify(this.currentData),
          })
        ).data;
      }
      this.is_progress = false;

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      this.is_progress = true;

      var response = (
        await this.$http_in.delete(
          `/optmgr/v1/BlackList/${this.currentData._id}`
        )
      ).data;
      this.is_progress = false;

      if (response != null || response != undefined) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        number: "",
        is_inbound: true,
        is_outbound: true,
        description: "",
      };
    },
    updateSelected(item) {
      this.currentData = item;
      console.log("item", item);
      this.isEdit = true;
      //this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        console.log("res", result);
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const extensOptions = [
      { label: "123", value: "123" },
      { label: "456", value: "456" },
      { label: "9999", value: "9999" },
      { label: "654", value: "654" },
    ];
    const projectOptions = [
      { label: "Abc", value: "1" },
      { label: "Proje2", value: "2" },
      { label: "Proje3", value: "3" },
      { label: "Proje4", value: "4" },
    ];
    const profileOptions = [
      { label: "Admin", value: "1" },
      { label: "Profil2", value: "2" },
      { label: "Profil3", value: "3" },
      { label: "Profil4", value: "4" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
