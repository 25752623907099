<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_agentGroup") }}
            </b-card-title>
            <!-- <b-card-sub-title class="mt-1">
              {{ lang("t_AgentGroup") }}
            </b-card-sub-title> -->
          </b-card-header>

          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>

              <b-button
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="plus"></b-icon>
              </b-button>
              <!-- <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
                <b-icon icon="funnel"></b-icon>
              </b-button> -->
            </b-card-text>
            <b-table
              :filter="searchQuery"
              small
              hover
              :fields="tableColumns"
              :items="records"
              responsive
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="records.length == 0"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
              <template #cell(agents)="data">
                {{ data.value }}
                <!-- <b-badge
                  v-for="(_queue, _key) in data.value
                    .map((e) => e.display_name)
                    .slice(0, 5)"
                  :key="_key"
                  variant="light-primary"
                  class="mr-50"
                >
                  {{ _queue }}
                </b-badge> -->
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  boundary="viewport"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="updateSelected(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    records.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="records.length"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="set_agent_group"
    >
      <b-form-group :label="lang('t_displayName')" label-for="AgentGroup">
        <b-form-input
          :placeholder="lang('t_displayName')"
          id="AgentGroup"
          v-model="agent_group.display_name"
          trim
        />
      </b-form-group>
      <b-form-group :label="lang('t_agents')" label-for="profile">
        <v-select
          v-model="agent_group.agents"
          :options="agents"
          :placeholder="lang('t_agents')"
          label="username"
          multiple
          :closeOnSelect="false"
          :reduce="(val) => val.identifier"
        >
        </v-select>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";

import {
  VBModal,
  BModal,
  BButton,
  BRow,
  BCol,
  BBadge,
  BFormInput,
  BFormGroup,
  BCard,
  BCardText,
  BLink,
  BForm,
  BFormCheckbox,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BContainer,

} from "bootstrap-vue";

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      modal_record_details: false,
      isEdit: false,
      perPage: 10,
      currentPage: 1,
      searchQuery: '',
      records: [],
      agents: [],
      agent_group: {
        internal_name: "",
        display_name: "",
        agents: [],
      },
      tableColumns: [
        {
          key: "display_name",
          label: globalThis._lang("t_displayName"),
          sortable: true,
        },
        {
          key: "agents",
          label: globalThis._lang("t_agents"),
          sortable: true,
        },

        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: { width: '5vh' }
        },
      ],
    };
  },
  components: {
    BModal,
    BButton,
    BRow,
    BCol,
    BBadge,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BFormGroup,
    BForm,
    BFormCheckbox,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BContainer,
  },
  directives: {
    "b-modal": VBModal,
  },
  mounted: async function () {
    await this.get_agents();
    this.get_records();
    this.create_new();
  },
  computed: {
    items() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.records.slice(start, end);
    },

  },
  methods: {
    displayItems(items) {
      if (items.length <= 5) {
        return items;
      } else {
        let displayedItems = items.slice(0, 5);
        return displayedItems;
      }
    },

    get_records: async function () {
      this.is_progress = true;
      var response = (await this.$http_in.get(`/optmgr/v1/AgentGroup`)).data;

      this.records = response;
      this.is_progress = false;
      console.log(" this.records", this.records);
    },
    get_agents: async function () {
      this.is_progress = true;
      var response = (await this.$http_in.get(`/optmgr/v1/AllUser`)).data;
      console.log("User", response);
      this.agents = response;
      this.is_progress = false;
    },

    delete_agent_group: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.delete(
          `/optmgr/v1/AgentGroup/${this.agent_group._id}`
        )
      ).data;
      this.is_progress = false;
      if (response != null || response != undefined) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        this.create_new();
        this.get_records();
      }
    },

    set_agent_group: async function () {
      if (
        !this.agent_group.display_name
      ) {
        this.$swal({
          icon: "warning",
          title: "",
          text: globalThis._lang("t_pleaseEnterTheLineGroupName"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }


      this.is_progress = true;

      var response;
      if (!this.edit) {
        response = (
          await this.$http_in.post(`/optmgr/v1/AgentGroup`, {
            data: JSON.stringify(this.agent_group),
          })
        ).data;
      } else {
        response = (
          await this.$http_in.put(`/optmgr/v1/AgentGroup`, {
            data: JSON.stringify(this.agent_group),
          })
        ).data;
      }

      this.is_progress = false;

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.create_new();
      this.get_records();
    },

    confirmText(item) {
      this.agent_group = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        console.log("res", result);
        if (result.value) {
          this.delete_agent_group();
        }
      });
    },

    updateSelected(selectedItem) {
      console.log("updateSelected", selectedItem);


      this.edit = true;
      this.agent_group = selectedItem;
      this.modal_record_details = true;


    },
    create_new() {
      this.edit = false;
      this.agent_group = {
        internal_name: "",
        display_name: "",
        agents: [],
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
