<template>
  <div>
    <finish-codes></finish-codes>
  </div>
</template>

<script>
import { BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BLink, BButton } from 'bootstrap-vue'
// import SmsSender from './SmsSender.vue'

// import Categories from './Categories/list/List.vue'
import FinishCodes from './FinishCodes/list/List.vue'

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      // selectedButton: 'categories',
      // optionsButton: [
      //   { text: 'Anonslar', value: 'announcements' },
      //   { text: 'Kuyruklar', value: 'inbound_routes' },
      //   { text: 'Projeler', value: 'ivr' },
      //   { text: 'Projeler', value: 'lines' },
      //   { text: 'Projeler', value: 'time-conditions' },
      //   // { text: 'Ayarlar', value: 'settings' },
      // ],
    }
  },
  components: {
    // Categories,
    FinishCodes,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,

  },

}
</script>

<style>
</style>
