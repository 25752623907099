<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <!-- Table Container Card -->

    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_shifts") }}
            </b-card-title>
            <!-- <b-card-sub-title class="mt-1">
              {{ lang("t_blackList") }}
            </b-card-sub-title> -->
          </b-card-header>

          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  @change="get_records_by_search"
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>

              <b-button
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button
                @click="modal_filter = true"
                size="sm"
                class="px-50 py-50"
                variant="flat-secondary"
              >
                <b-icon icon="funnel"></b-icon>
              </b-button>
              <b-dropdown
                dropleft
                boundary="viewport"
                size="sm"
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                  @click="
                    modal_excel = true;
                    isEdit = false;
                    reset_record();
                  "
                >
                  <b-icon icon="upload"></b-icon>
                  <span class="align-middle ml-50">{{
                    lang("t_importRecords")
                  }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-card-text>
            <b-table
              :filter="searchQuery"
              small
              style="max-height: 100vh"
              hover
              :fields="tableColumns"
              :items="records"
              responsive
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="records.length == 0"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>

              <template #cell(is_inbound)="data">
                <feather-icon
                  :icon="data.item.is_inbound ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(paid_leave)="data">
                <feather-icon
                  :icon="data.value ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(unpaid_leave)="data">
                <feather-icon
                  :icon="data.value ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  boundary="viewport"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="updateSelected(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    records.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="totalUsers"
                first-number
                last-number
                @input="get_records"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_record"
    >
      <b-row>
        <b-col>
          <b-form-group :label="lang('t_date')">
            <date-range-picker
              :opens="'left'"
              append-to-body
              class="d-block"
              ref="picker"
              :autoApply="true"
              :locale-data="{
                direction: 'ltr',
                format: 'dd/mm/yyyy',
                separator: ' / ',
                applyLabel: 'Uygula',
                cancelLabel: 'İptal',
                weekLabel: 'H',
                customRangeLabel: 'Özel Aralık',
                daysOfWeek: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
                monthNames: [
                  'Oca',
                  'Şub',
                  'Mar',
                  'Nis',
                  'May',
                  'Haz',
                  'Tem',
                  'Ağu',
                  'Eyl',
                  'Eki',
                  'Kas',
                  'Ara',
                ],
                firstDay: 1,
              }"
              v-model="currentData.date_ranges"
              :timePicker="false"
            >
              <template v-slot:input="picker">
                <span>
                  {{ setDateFormat(picker.startDate) | date }} -
                  {{ setDateFormat(picker.endDate) | date }}
                </span>
              </template>
              <template #ranges="ranges">
                <div class="ranges">
                  <b-row
                    class="mx-25"
                    v-for="(range, name) in ranges.ranges"
                    :key="name"
                  >
                    <b-col>
                      <b-button
                        size="sm"
                        block
                        @click="customClickRange(range)"
                        variant="flat-primary"
                      >
                        {{ name }}
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
                <!-- <div class="ranges">
                  <ul>
                    <li v-for="(range, name) in ranges.ranges" :key="name">
                     
                      <b>{{ name }}</b>
                      <small class="text-muted"
                        >{{ range[0].toDateString() }} -
                        {{ range[1].toDateString() }}</small
                      >
                    </li>
                  </ul>
                </div> -->
              </template>
              <div slot="footer" slot-scope="data" class="slot">
                <div
                  class="border-top px-1 py-1 d-flex justify-content-between align-items-center"
                >
                  <div>
                    {{ data.rangeText }}
                  </div>
                  <div class="float-right">
                    <b-button
                      size="sm"
                      @click="
                        picker.setStartDate({});
                        picker.setEndDate({});
                      "
                      variant="flat-secpndary"
                    >
                      {{ lang("t_clear") }}
                    </b-button>
                    <b-button
                      size="sm"
                      @click="data.clickApply"
                      v-if="!data.in_selection"
                      variant="flat-primary"
                    >
                      {{ lang("t_apply") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </date-range-picker>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group :label="lang('t_startTime')" label-for="startTime">
            <b-form-timepicker
              :show-seconds="false"
              v-model="currentData.shift_start"
              locale="tr"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="lang('t_endTime')" label-for="endTime">
            <b-form-timepicker
              :show-seconds="false"
              v-model="currentData.shift_end"
              locale="tr"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="lang('t_breakTime')" label-for="breakTime">
            <b-form-input type="number" v-model="currentData.break_time" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group :label="lang('t_users')">
            <v-select
              multiple
              :placeholder="lang('t_users')"
              v-model="currentData.users"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="username"
              :reduce="(item) => item.identifier"
              :options="agents"
              deselectFromDropdown
              :closeOnSelect="false"
            >
              <template #list-header>
                <div class="my-50 mx-50">
                  <b-button
                    class="w-50"
                    size="sm"
                    variant="flat-secondary"
                    @click="
                      () => {
                        currentData.users = agents.map((e) => e.identifier);
                      }
                    "
                  >
                    <feather-icon size="14" icon="CheckCircleIcon" />
                    {{ lang("t_selectAll") }}
                  </b-button>
                  <b-button
                    class="w-50"
                    size="sm"
                    variant="flat-secondary"
                    @click="
                      () => {
                        currentData.users = [];
                      }
                    "
                  >
                    <feather-icon size="14" icon="XOctagonIcon" />
                    {{ lang("t_clear") }}
                  </b-button>
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="lang('t_edit')"
      centered
      v-model="modal_edit_record"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="edit_record"
    >
      <b-row>
        <b-col>
          <b-form-group :label="lang('t_startTime')" label-for="startTime">
            <b-form-timepicker v-model="editedItem.shift_start" locale="tr" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="lang('t_endTime')" label-for="endTime">
            <b-form-timepicker v-model="editedItem.shift_end" locale="tr" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="lang('t_breakTime')" label-for="breakTime">
            <b-form-input
              type="number"
              v-model="editedItem.break_time"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="lang('t_missingTime')" label-for="missingTime">
            <b-form-input
              type="number"
              v-model="editedItem.missing_time"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-checkbox
            @input="onPaidLeaveChange"
            inline
            v-model="editedItem.paid_leave"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <!-- <i class="vs-icon feather icon-check" /> -->
                {{ lang("t_paidLeave") }}
              </span>
            </span>
          </b-form-checkbox>
          <b-form-checkbox
            @input="onUnpaidLeaveChange"
            inline
            v-model="editedItem.unpaid_leave"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <!-- <i class="vs-icon feather icon-check" /> -->
                {{ lang("t_unpaidLeave") }}
              </span>
            </span>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      @ok="read_data"
      v-model="modal_excel"
      :title="lang('t_importRecords')"
      centered
    >
      <b-row align-h="end">
        <b-button
          target="_blank"
          href="template_examples/voyce_blacklist_import.xlsx"
          :exact="true"
          size="sm"
          variant="flat-success"
          >{{ lang("t_exampleFile") }}</b-button
        >
      </b-row>
      <b-form-group :label="lang('t_file')" label-for="file">
        <b-form-file
          :browse-text="lang('t_file')"
          v-model="excelData.files"
          :placeholder="lang('t_uploadFileOnClick')"
          :drop-placeholder="lang('t_uploadFile')"
          no-drop
          required
          accept=".xlsx, .xls"
        />
      </b-form-group>

      <b-form-group :label="lang('t_description')" label-for="description">
        <b-form-textarea
          v-model="excelData.description"
          id="description"
          :placeholder="lang('t_description')"
          rows="3"
        >
        </b-form-textarea>
      </b-form-group>

      <b-form-checkbox
        inline
        v-model="excelData.is_inbound"
        class="custom-control-primary"
      >
        <span class="vs-checkbox">
          <span class="vs-checkbox--check">
            <!-- <i class="vs-icon feather icon-check" /> -->
            {{ lang("t_inbound") }}
          </span>
        </span>
      </b-form-checkbox>
      <b-form-checkbox
        inline
        v-model="excelData.is_outbound"
        class="custom-control-primary"
      >
        <span class="vs-checkbox">
          <span class="vs-checkbox--check">
            <!-- <i class="vs-icon feather icon-check" /> -->
            {{ lang("t_outbound") }}
          </span>
        </span>
      </b-form-checkbox>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      v-model="modal_filter"
      :title="lang('t_filters')"
      centered
      @ok="getRecordsByFilter"
    >
      <b-form-group :label="lang('t_date')">
        <date-range-picker
          :opens="'left'"
          append-to-body
          class="d-block"
          ref="picker"
          :autoApply="true"
          :locale-data="{
            direction: 'ltr',
            format: 'dd/mm/yyyy',
            separator: ' / ',
            applyLabel: 'Uygula',
            cancelLabel: 'İptal',
            weekLabel: 'H',
            customRangeLabel: 'Özel Aralık',
            daysOfWeek: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
            monthNames: [
              'Oca',
              'Şub',
              'Mar',
              'Nis',
              'May',
              'Haz',
              'Tem',
              'Ağu',
              'Eyl',
              'Eki',
              'Kas',
              'Ara',
            ],
            firstDay: 1,
          }"
          v-model="filterValues.date_ranges"
          :timePicker="false"
        >
          <template v-slot:input="picker">
            <span>
              {{ setDateFormat(picker.startDate) | date }} -
              {{ setDateFormat(picker.endDate) | date }}
            </span>
          </template>
          <template #ranges="ranges">
            <div class="ranges">
              <b-row
                class="mx-25"
                v-for="(range, name) in ranges.ranges"
                :key="name"
              >
                <b-col>
                  <b-button
                    size="sm"
                    block
                    @click="customClickRange(range)"
                    variant="flat-primary"
                  >
                    {{ name }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </template>
          <div slot="footer" slot-scope="data" class="slot">
            <div
              class="border-top px-1 py-1 d-flex justify-content-between align-items-center"
            >
              <div>
                {{ data.rangeText }}
              </div>
              <div class="float-right">
                <b-button
                  size="sm"
                  @click="
                    picker.setStartDate({});
                    picker.setEndDate({});
                  "
                  variant="flat-secpndary"
                >
                  {{ lang("t_clear") }}
                </b-button>
                <b-button
                  size="sm"
                  @click="data.clickApply"
                  v-if="!data.in_selection"
                  variant="flat-primary"
                >
                  {{ lang("t_apply") }}
                </b-button>
              </div>
            </div>
          </div>
        </date-range-picker>
      </b-form-group>
      <b-form-group :label="lang('t_users')">
        <v-select
          multiple
          :placeholder="lang('t_users')"
          v-model="filterValues.users"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="username"
          :reduce="(item) => item.identifier"
          :options="agents"
          deselectFromDropdown
          :closeOnSelect="false"
        >
          <template #list-header>
            <div class="my-50 mx-50">
              <b-button
                class="w-50"
                size="sm"
                variant="flat-secondary"
                @click="
                  () => {
                    filterValues.users = agents.map((e) => e.identifier);
                  }
                "
              >
                <feather-icon size="14" icon="CheckCircleIcon" />
                {{ lang("t_selectAll") }}
              </b-button>
              <b-button
                class="w-50"
                size="sm"
                variant="flat-secondary"
                @click="
                  () => {
                    filterValues.users = [];
                  }
                "
              >
                <feather-icon size="14" icon="XOctagonIcon" />
                {{ lang("t_clear") }}
              </b-button>
            </div>
          </template>
        </v-select>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormTimepicker,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BFormTextarea,
  BSpinner,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  BCardText,
  BFormFile
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const startDate = new Date();
startDate.setHours(0, 0, 0, 0);
const endDate = new Date();
endDate.setHours(23, 55, 0, 0);
export default {
  components: {
    BFormTimepicker,
    DateRangePicker,
    // UsersListFilters,
    BFormFile,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormTextarea,
    vSelect,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BCardText,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      isEdit: false,
      modal_excel: false,
      modal_edit_record: false,
      modal_filter: false,
      currentData: {
        date_ranges: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        shift_start: '09:00',
        shift_end: '18:30',
        break_time: 85,
        users: [],
      },
      editedItem: {
        "shift_start": "09:00",
        "shift_end": "18:30",
        "missing_time": 0,
        "break_time": 85,
        "paid_leave": false,
        "unpaid_leave": false,
      },
      filterValues: {
        date_ranges: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        users: [],
      },
      excelData: {
        files: null,
        is_inbound: true,
        is_outbound: true,
        description: "",
      },
      searchQuery: "",
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
      agents: [],
      records: [],
      tableColumns: [
        { key: "shift_date", label: globalThis._lang("t_date"), sortable: true },
        {
          key: "user",
          label: globalThis._lang("t_agent"),
          sortable: true,
        },
        {
          key: "shift_start",
          label: globalThis._lang("t_startTime"),
          sortable: true,
        },
        {
          key: "shift_end",
          label: globalThis._lang("t_endTime"),
          sortable: true,
        },
        {
          key: "break_time",
          label: globalThis._lang("t_breakTime"),
          sortable: true,
        },
        {
          key: "missing_time",
          label: globalThis._lang("t_missingTime"),
          sortable: true,
        },
        {
          key: "paid_leave",
          label: globalThis._lang("t_paidLeave"),
          sortable: true,
        },
        {
          key: "unpaid_leave",
          label: globalThis._lang("t_unpaidLeave"),
          sortable: true,
        },

        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: { width: '5vh' }

        },
      ],
    };
  },
  mounted: function () {
    this.get_users();
    this.getRecordsByFilter();
  },
  methods: {
    getRecordsByFilter: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.post(`/optmgr/v1/ShiftByFilter`, {
          ...this.filterValues,
          perPage: this.perPage,
          currentPage: this.currentPage,
          searchQuery: this.searchQuery
        })).data;
      console.log("resp", response);
      this.records = response.result;
      this.totalUsers = response.count;
      this.is_progress = false;
    },

    onPaidLeaveChange() {
      if (this.editedItem.paid_leave) {
        this.editedItem.unpaid_leave = false;
      }

    },
    onUnpaidLeaveChange() {
      if (this.editedItem.unpaid_leave) {
        this.editedItem.paid_leave = false;
      }

    },

    updateTimeFormat() {
      this.currentData.shift_start = this.currentData.shift_start.substring(0, 5);
      this.currentData.shift_end = this.currentData.shift_end.substring(0, 5);
    },

    customClickRange(range) {
      let startDate = range[0];
      let endDate = new Date(range[1]);
      endDate.setHours(23, 59, 59, 0);
      this.currentData.date_ranges = { startDate, endDate };
    },
    setDateFormat(date_string) {
      const date_object = new Date(date_string);

      const day = String(date_object.getDate()).padStart(2, '0');
      const month = String(date_object.getMonth() + 1).padStart(2, '0');
      const year = date_object.getFullYear();
      // const hour = String(date_object.getHours()).padStart(2, '0');
      // const minute = String(date_object.getMinutes()).padStart(2, '0');

      const formatted_date = `${day}/${month}/${year}`;
      return formatted_date;
    },

    async read_data() {
      this.progress = true;
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = globalThis.XLSX.read(bstr, { type: "binary" });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = globalThis.XLSX.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
        });
        var _data = this.xlsxToJson(data);

        for (const item of _data) {
          this.currentData = {
            number: item["Telefon"] ?? '',
            is_inbound: this.excelData.is_inbound,
            is_outbound: this.excelData.is_outbound,
            description: this.excelData.description,
          };
          this.add_record();
        }
        this.progress = false;
        this.modal_excel = false;
      };
      reader.readAsBinaryString(this.excelData.files);
    },

    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },

    get_records_by_search: async function () {
      if (this.searchQuery == "") {
        this.getRecordsByFilter();
        return;
      }
      this.is_progress = true;

      var response = (
        await this.$http_in.get(`/optmgr/v1/Shift/${this.searchQuery}`)
      ).data;
      console.log("resp", response);
      this.records = response.result;
      this.totalUsers = response.count;
      this.is_progress = false;
    },
    get_users: async function () {
      var response = (await this.$http_in.get(`spv/v1/User`)).data;

      this.agents = response;
      // this.agents = response.filter(e => e.projects.includes(globalThis.user.selected_project));
    },

    check_data() {
      if (this.currentData.users.length == 0) {
        return true;
      }
      return false;
    },

    add_record: async function () {
      try {
        if (this.check_data()) {
          this.$swal({
            title: globalThis._lang("t_warning"),
            text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          return;
        }
        this.is_progress = true;
        this.currentData.shift_start = this.currentData.shift_start.substring(0, 5);
        this.currentData.shift_end = this.currentData.shift_end.substring(0, 5);
        var response = (
          await this.$http_in.post(`/optmgr/v1/Shift`, {
            data: this.currentData,
          })
        ).data;


        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });

      } catch (error) {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } finally {
        this.is_progress = false;
      }

      this.reset_record();
      this.getRecordsByFilter();
    },

    edit_record: async function () {

      this.is_progress = true;

      var response = (
        await this.$http_in.put(`/optmgr/v1/Shift`, {
          data: this.editedItem,
        })
      ).data;


      this.is_progress = false;

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.getRecordsByFilter();
    },
    delete_record: async function () {
      this.is_progress = true;

      var response = (
        await this.$http_in.delete(
          `/optmgr/v1/Shift/${this.editedItem._id}`
        )
      ).data;
      this.is_progress = false;

      if (response != null || response != undefined) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.reset_record();
      this.getRecordsByFilter();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        date_ranges: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        shift_start: '09:00',
        shift_end: '18:30',
        break_time: 85,
        users: [],
      };
    },
    updateSelected(item) {
      this.editedItem = item;
      console.log("item", item);
      this.isEdit = true;
      //this.isAddNewUserSidebarActive = true;
      this.modal_edit_record = true;
    },
    confirmText(item) {
      this.editedItem = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        console.log("res", result);
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const extensOptions = [
      { label: "123", value: "123" },
      { label: "456", value: "456" },
      { label: "9999", value: "9999" },
      { label: "654", value: "654" },
    ];
    const projectOptions = [
      { label: "Abc", value: "1" },
      { label: "Proje2", value: "2" },
      { label: "Proje3", value: "3" },
      { label: "Proje4", value: "4" },
    ];
    const profileOptions = [
      { label: "Admin", value: "1" },
      { label: "Profil2", value: "2" },
      { label: "Profil3", value: "3" },
      { label: "Profil4", value: "4" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";

.daterangepicker {
  font-family: "Montserrat", "OpenSans", Helvetica, Arial, serif;
}
.daterangepicker td.in-range {
  background-color: #bfb9fa;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}
.reportrange-text {
  padding: 8px 10px !important;
}

.daterangepicker td.active:hover {
  background-color: #7367f0;
  border-color: transparent;
  color: #fff;
}

.daterangepicker .ranges li.active {
  background-color: #7367f0;
  color: #fff;
}

.daterangepicker select.hourselect {
  text-align: center;
}
.daterangepicker select.minuteselect {
  text-align: center;
}
</style>
