<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_profiles") }}
            </b-card-title>
            <!-- <b-card-sub-title class="mt-1">
              {{ lang("t_profiles") }}
            </b-card-sub-title> -->
          </b-card-header>

          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>

              <b-button
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
                <b-icon icon="funnel"></b-icon>
              </b-button>
            </b-card-text>
            <b-table
              :filter="searchQuery"
              small
              hover
              :fields="tableColumns"
              :items="profiles"
              responsive
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="profiles.length == 0"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
              <template #cell(save_record)="data">
                <feather-icon
                  :icon="data.value ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(line)="data">
                {{
                  lines.find((e) => e.internal_name == data.value)
                    ? lines.find((e) => e.internal_name == data.value)
                        .display_name
                    : data.value
                }}
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  boundary="viewport"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="updateSelected(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="DeleteProfilePopup(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    profiles.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="profiles.length"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      size="xl"
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="SetProfile"
    >
      <b-row>
        <b-col sm="12" md="3" lg="3">
          <b-form-group :label="lang('t_displayName')">
            <b-form-input v-model="profile.display_name" trim />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="3" lg="3">
          <b-form-group :label="lang('t_projects')">
            <v-select
              :closeOnSelect="false"
              v-model="selected_objects.projects"
              multiple
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="object_list.projects"
              deselectFromDropdown
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="3" lg="3">
          <b-form-group :label="lang('t_queues')">
            <v-select
              :closeOnSelect="false"
              v-model="selected_objects.queues"
              multiple
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="object_list.queues"
              deselectFromDropdown
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="3" lg="3">
          <b-form-group :label="lang('t_users')">
            <v-select
              :closeOnSelect="false"
              v-model="selected_objects.users"
              multiple
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="username"
              :reduce="(item) => item.identifier"
              :options="object_list.users"
              deselectFromDropdown
            />
          </b-form-group>
        </b-col>
      </b-row>
      <span>{{ lang("t_permissions") }}</span>
      <div class="d-flex align-item-center justify-content-between mt-50">
        <div
          v-for="(item, i) in Object.keys(tmp_privileges).slice(0, 5)"
          :key="i"
          style="width: 24rem"
        >
          <b-card
            style="min-height: 30vh; max-height: 30vh; overflow-y: auto"
            class="shadow-none border"
          >
            <div class="mb-1">
              <b-row>
                <b-col class="d-flex justify-content-center align-items-center">
                  <b-form-input
                    v-model="filterText[item]"
                    @input="filterPrivileges(item)"
                    size="sm"
                    :placeholder="lang(`t_${item}`)"
                    trim
                  />
                  <b-button
                    @click="checkAllPrivileges(false, item)"
                    size="sm"
                    class="px-50 py-50 mx-50"
                    variant="flat-secondary"
                  >
                    <i class="bi bi-dash-circle font-medium-1"></i>
                  </b-button>
                  <b-button
                    @click="checkAllPrivileges(true, item)"
                    size="sm"
                    class="px-50 py-50"
                    variant="flat-secondary"
                  >
                    <i class="bi bi-check-circle font-medium-1"></i>
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div style="min-height: 18vh; max-height: 18vh; overflow-y: auto">
              <div
                v-for="(item2, j) in tmp_privileges[item]"
                :key="j"
                class="d-flex align-items-center justify-content-between"
              >
                <b-form-checkbox
                  @change="add_to_selected(item, item2.internal_name)"
                  class="mt-25"
                  v-model="item2.checked"
                >
                  {{ item2.display_name }}
                </b-form-checkbox>
              </div>
            </div>
          </b-card>
        </div>
      </div>
      <div class="d-flex align-item-center justify-content-between">
        <div
          v-for="(item, i) in Object.keys(tmp_privileges).slice(
            5,
            Object.keys(tmp_privileges).length
          )"
          :key="i"
          style="width: 24rem"
        >
          <b-card
            style="min-height: 30vh; max-height: 30vh; overflow-y: auto"
            class="shadow-none border"
          >
            <div class="mb-1">
              <b-row>
                <b-col class="d-flex justify-content-center align-items-center">
                  <b-form-input
                    v-model="filterText[item]"
                    @input="filterPrivileges(item)"
                    size="sm"
                    :placeholder="lang(`t_${item}`)"
                    trim
                  />
                  <b-button
                    @click="checkAllPrivileges(false, item)"
                    size="sm"
                    class="px-50 py-50 mx-50"
                    variant="flat-secondary"
                  >
                    <i class="bi bi-dash-circle font-medium-1"></i>
                  </b-button>
                  <b-button
                    @click="checkAllPrivileges(true, item)"
                    size="sm"
                    class="px-50 py-50"
                    variant="flat-secondary"
                  >
                    <i class="bi bi-check-circle font-medium-1"></i>
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div style="min-height: 18vh; max-height: 18vh; overflow-y: auto">
              <div
                v-for="(item2, j) in tmp_privileges[item]"
                :key="j"
                class="d-flex align-items-center justify-content-between"
              >
                <b-form-checkbox
                  @change="add_to_selected(item, item2.internal_name)"
                  class="mt-25"
                  v-model="item2.checked"
                >
                  {{ item2.display_name }}
                </b-form-checkbox>
              </div>
            </div>
          </b-card>
        </div>
      </div>
      <!-- <b-row class="mt-50">
        <b-col
          cols="2"
          v-for="(item, i) in Object.keys(tmp_privileges)"
          :key="i"
        >
         
        </b-col>
    
      </b-row> -->

      <!-- <b-form-group :label="lang('t_permissions')" label-for="profile">
        <div id="app">
          <treeselect
            :placeholder="lang('t_permissions')"
            v-model="value"
            :multiple="true"
            :options="options"
          />
        </div>
      </b-form-group> -->
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select'

import {
  BAvatar, BFormCheckbox, BModal, BFormInput, BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BCardHeader, BLink, BButton,
  BCardFooter,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  BTable,
  BDropdown,
  BDropdownItem,
  BContainer,
  BPagination,
} from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { $themeColors } from "@themeConfig";

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      currentPage: 1,
      perPage: 10,
      searchQuery: '',
      value: null,
      // define options

      tableColumns: [
        {
          key: "display_name",
          label: globalThis._lang("t_displayName"),
          sortable: true,
        },
        // {
        //   key: "profiles",
        //   label: globalThis._lang("t_permissions"),
        //   sortable: true,
        // },

        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: { width: '5vh' }
        },
      ],
      is_progress: false,
      modal: false,
      modal_record_details: false,
      modal_object_list: false,
      modal_delete_object: false,
      isEdit: false,
      is_alert: false,
      search_list: '',
      alert_message: '',
      alert_type: 'warning',
      selected_profile: {},
      deleted_object: '',
      queue_names: new Map(),
      project_names: new Map(),
      queues: [],
      profiles: [],
      search_value: '',
      filterText: {
        status: '',
        finish_code: '',
        queue: '',
        dial_plan: '',
        menu: '',
      },

      selected_objects: {
        users: [],
        queues: [],
        projects: [],
      },
      object_list: {
        users: [],
        queues: [],
        projects: [],
      },

      temp_profile_objects: {
        users: [],
        queues: [],
        projects: [],
      },

      selected_privileges: {
        status: [],
        finish_code: [],
        queue: [],
        ivr: [],
        phone_book: [],
        dial_plan: [],
        menu: [],
      },
      tmp_privileges: {
        status: [],
        finish_code: [],
        queue: [],
        ivr: [],
        phone_book: [],
        dial_plan: [],
        menu: [],
        crm: [],
        other: [],
        report: [],
        lines: [],
      },
      tmp_privileges2: {
        status: [],
        finish_code: [],
        queue: [],
        ivr: [],
        phone_book: [],
        dial_plan: [],
        menu: [],
        crm: [],
        other: [],
        report: [],
        lines: [],

      },
      item: '',
      profile: {
        id: '',
        internal_name: '',
        display_name: '',
        privileges: [],
      },

    }
  },
  components: {
    BPagination,
    BContainer,
    BTable,
    Treeselect,
    BAvatar,
    BFormCheckbox,
    BModal,
    vSelect,
    BFormInput,
    BCardHeader,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
    BCardFooter,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
  },

  computed: {
    items() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.profiles.slice(start, end);
    },
  },
  methods: {
    filterPrivileges(item) {
      this.tmp_privileges[item] = [...this.tmp_privileges2[item].filter(e => e.display_name.toLowerCase().includes(this.filterText[item]))];
      if (this.filterText[item].trim() == '') {
        this.tmp_privileges[item] = [...this.tmp_privileges2[item]];
      }
    },
    add_to_selected(key, value) {
      if (!this.selected_privileges[key].includes(value)) {
        this.selected_privileges[key].push(value);
      }
      else {
        var index = this.selected_privileges[key].indexOf(value);
        if (index !== -1) {
          this.selected_privileges[key].splice(index, 1);
        }
      }
      console.log("selected_privileges", this.selected_privileges);
    },
    deleteObject(obj) {
      this.deleted_object = obj;
      this.$swal({
        title: this.lang('t_deleteRecord'),
        text: this.lang('t_areYouSureYouWantToDeleteTheSelectedRecord'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.DeleteProfileAssignment();

        }
      })
    },
    checkAll(check) {
      if (this.type_selection == 1) {
        for (const item of this.object_list.temp_users) {
          check ? item.checked = true : item.checked = false;
        }

        this.object_list.temp_users = [...this.object_list.temp_users];

      }
      if (this.type_selection == 2) {
        for (const item of this.object_list.temp_queues) {
          check ? item.checked = true : item.checked = false;
        }

        this.object_list.temp_queues = [...this.object_list.temp_queues];
      }
      if (this.type_selection == 3) {
        for (const item of this.object_list.temp_projects) {
          check ? item.checked = true : item.checked = false;
        }

        this.object_list.temp_projects = [...this.object_list.temp_projects];
      }
    },

    checkAllPrivileges(check, key) {
      if (check) {
        for (const row of this.tmp_privileges[key]) {
          row.checked = true;
          if (!this.selected_privileges[key].includes(row.internal_name)) {
            this.selected_privileges[key].push(row.internal_name);
          }
        }
        for (const row of this.tmp_privileges2[key]) {
          row.checked = true;
          if (!this.selected_privileges[key].includes(row.internal_name)) {
            this.selected_privileges[key].push(row.internal_name);
          }
        }
      } else {
        for (const row of this.tmp_privileges[key]) {
          row.checked = false;
        }
        for (const row of this.tmp_privileges2[key]) {
          row.checked = false;
        }
        this.selected_privileges[key] = [];
      }
      this.tmp_privileges[key] = [...this.tmp_privileges[key]];
      this.tmp_privileges2[key] = [...this.tmp_privileges2[key]];
    },
    DeleteProfilePopup: function (item) {
      this.$swal({
        title: this.lang('t_deleteRecord'),
        text: this.lang('t_areYouSureYouWantToDeleteTheSelectedRecord'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.DeleteProfile(item);

        }
      })
    },
    DeleteProfile: async function (item) {
      this.profile = item;
      this.is_progress = true;
      this.modal = false;
      var response = (
        await this.$http_in.delete(`Auth/v1/Profile/${this.profile.internal_name}`, {
          headers: {
            authorization: globalThis.qm_token,
          },
        })
      ).data;
      this.is_progress = false;
      if (response != null || response != undefined) {
        this.$swal({
          icon: 'success',
          title: this.lang('t_processSuccess'),
          text: `${this.profile.display_name} ${globalThis._lang("t_profileHasBeenDeletedSuccessfully")}`,
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
        this.GetProfiles();
        this.reset_record();
      }
    },
    DeleteProfileAssignment: async function () {
      this.modal_delete_object = false;
      var response = (
        await this.$http_in.delete(`Auth/v1/ProfileAssignment/${this.deleted_object}/${this.profile.internal_name}`, {
          headers: {
            authorization: globalThis.qm_token
          },
        })
      ).data;
      await this.GetProfileObjects();
      await this.GetUsers();
      await this.GetQueues();
      await this.GetProjects();
      this.updateSelected(this.profile);
      this.$swal({
        icon: 'success',
        title: this.lang('t_processSuccess'),
        text: this.lang('t_customersHaveBeenSuccessfullyDeleted'),
        confirmButtonText: this.lang('t_ok'),
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });


    },
    test() {
      this.modal_object_list = true;
      console.log('this.selected_objects.users', this.selected_objects.users);
    },
    reset_record() {
      this.isEdit = false;
      this.profile = {
        id: '',
        internal_name: '',
        display_name: '',
        privileges: [],
      };

      this.selected_objects = {
        users: [],
        queues: [],
        projects: [],
      };

      this.profile.privileges = {
        status: [],
        finish_code: [],
        queue: [],
        ivr: [],
        phone_book: [],
        dial_plan: [],
        menu: [],
        crm: [],
        other: [],
        report: [],
        lines: [],

      };
      this.selected_privileges = {
        status: [],
        finish_code: [],
        queue: [],
        ivr: [],
        phone_book: [],
        dial_plan: [],
        menu: [],
        crm: [],
        other: [],
        report: [],
        lines: [],

      };

      if (![undefined, null, []].includes(this.selected_privileges)) {
        for (const item of Object.keys(this.tmp_privileges)) {
          for (const item2 of this.tmp_privileges[item]) {
            item2.checked = false;
            if (this.selected_privileges[item] && this.selected_privileges[item].includes(item2.internal_name)) {
              item2.checked = false;
              if (!this.selected_privileges[item].includes(item2.internal_name)) {
                this.selected_privileges[item].push(item2.internal_name);
              }
            }
          }
        }
      }
      if (![undefined, null, []].includes(this.selected_privileges)) {
        for (const item of Object.keys(this.tmp_privileges2)) {
          for (const item2 of this.tmp_privileges2[item]) {
            item2.checked = false;
            if (this.selected_privileges[item] && this.selected_privileges[item].includes(item2.internal_name)) {
              item2.checked = false;
              if (!this.selected_privileges[item].includes(item2.internal_name)) {
                this.selected_privileges[item].push(item2.internal_name);
              }
            }
          }
        }
      }


    },

    SetProfile: async function () {
      if (this.profile.display_name == '' || (this.selected_objects.users.length == 0 && this.selected_objects.queues.length == 0 && this.selected_objects.projects.length == 0)) {

        this.$swal({
          title: 'Warning!',
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
        return;
      }
      // console.log("aaa", this.object_list.temp_users.filter(e => e.checked == true));
      // this.selected_objects.users = this.object_list.temp_users.filter(e => e.checked == true);
      // this.selected_objects.queues = this.object_list.temp_queues.filter(e => e.checked == true);
      // this.selected_objects.projects = this.object_list.temp_projects.filter(e => e.checked == true);
      // return;

      this.profile.privileges = this.selected_privileges;
      this.is_progress = true;
      var response;
      if (!this.isEdit) {
        response = (
          await this.$http_in.post(`Auth/v1/Profile`,
            {
              data: JSON.stringify(this.profile),
              profile_assigments: JSON.stringify(this.selected_objects),
            }
          )
        ).data;
      } else {
        response = (
          await this.$http_in.put(`Auth/v1/Profile`,
            {
              data: JSON.stringify(this.profile),
              profile_assigments: JSON.stringify(this.selected_objects),
            }
          )
        ).data;
      }


      Promise.all([
        this.GetUsers(),
        this.GetQueues(),
        this.GetProjects(),
        this.GetProfileObjects(),
        this.GetProfiles(),
      ]);

      this.reset_record();
      // this.updateSelected(this.profile);
      this.is_progress = false;

      console.log(response.command);
      if (response != null || response != undefined) {
        if (response.command == 'UPDATE') {
          this.$swal({
            icon: 'success',
            title: this.lang('t_processSuccess'),
            text: `${this.profile.display_name} ${globalThis._lang("t_profileHasBeenUpdatedSuccessfully")}`,
            confirmButtonText: this.lang('t_ok'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        } else {
          this.$swal({
            icon: 'success',
            title: this.lang('t_processSuccess'),
            text: globalThis._lang("t_profileHasBeenCreatedSuccessfully"),
            confirmButtonText: this.lang('t_ok'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });

          // this.reset_record();
          await this.GetProfiles(true);
        }
      }
    },

    GetProfiles: async function (update = false) {
      this.is_progress = true;
      var response = (
        await this.$http_in.get(`Auth/v1/Profiles`)
      ).data;

      console.log(response);
      this.profiles = [...response];
      this.is_progress = false;
      // this.profiles = response;
      // if (update) {
      //   this.updateSelected(response[response.length - 1]);
      // }
    },
    updateSelected: function (selectedItem) {
      console.log(selectedItem);
      this.profile = selectedItem;

      this.isEdit = true;
      this.is_alert = false;
      this.selected_privileges = {
        status: [],
        finish_code: [],
        queue: [],
        ivr: [],
        phone_book: [],
        dial_plan: [],
        menu: [],
        crm: [],
        other: [],
        report: [],
        lines: [],

      };
      if (![undefined, null, []].includes(selectedItem.privileges)) {
        for (const item of Object.keys(this.tmp_privileges)) {
          for (const item2 of this.tmp_privileges[item]) {
            item2.checked = false;
            if (selectedItem.privileges[item] && selectedItem.privileges[item].includes(item2.internal_name)) {
              item2.checked = true;
              if (!this.selected_privileges[item].includes(item2.internal_name)) {
                this.selected_privileges[item].push(item2.internal_name);
              }
            }
          }
        }
      }
      if (![undefined, null, []].includes(selectedItem.privileges)) {
        for (const item of Object.keys(this.tmp_privileges2)) {
          for (const item2 of this.tmp_privileges2[item]) {
            item2.checked = false;
            if (selectedItem.privileges[item] && selectedItem.privileges[item].includes(item2.internal_name)) {
              item2.checked = true;
              if (!this.selected_privileges[item].includes(item2.internal_name)) {
                this.selected_privileges[item].push(item2.internal_name);
              }
            }
          }
        }
      }

      console.log("this.tmp_privileges", this.tmp_privileges);
      this.profile.internal_name =
        this.profile.internal_name ||
        `${this.$store.state.agent.Domain}_profile_${this.profile.display_name.toLowerCase().split(' ').join('_')}`;
      console.log('this.profile.internal_name', this.profile);
      this.profile.internal_name = this.remove_turkish_characters(this.profile.internal_name);

      console.log("this.temp_profile_objects", this.temp_profile_objects);
      this.selected_objects.users = this.temp_profile_objects.users.filter((e) => e.profile_name == this.profile.internal_name).map(e => e.object_name);
      this.selected_objects.queues = this.temp_profile_objects.queues.filter(
        (e) => e.profile_name == this.profile.internal_name
      ).map(e => e.object_name);
      this.selected_objects.projects = this.temp_profile_objects.projects.filter(
        (e) => e.profile_name == this.profile.internal_name
      ).map(e => e.object_name);



      this.modal_record_details = true;
    },
    remove_turkish_characters(text) {
      var charMap = {
        Ç: 'C',
        Ö: 'O',
        Ş: 'S',
        İ: 'I',
        I: 'i',
        Ü: 'U',
        Ğ: 'G',
        ç: 'c',
        ö: 'o',
        ş: 's',
        ı: 'i',
        ü: 'u',
        ğ: 'g',
      };

      var str = text;

      var str_array = str.split('');

      for (var i = 0, len = str_array.length; i < len; i++) {
        str_array[i] = charMap[str_array[i]] || str_array[i];
      }

      str = str_array.join('');

      return str.replace(/[çöşüğı]/gi, '');
    },

    // ----------- NEW --------

    GetStatusAndCategories: async function () {
      var response = (
        await this.$http_in.get(`optmgr/v1/Status`)
      ).data;

      var _status = response.status
        .filter((s) => !s.is_system)
        .map((e) => {
          return {
            internal_name: e._id,
            display_name: e.display_name,
          };
        });

      console.log("aaaadd", _status);
      this.tmp_privileges.status = _status;
      this.tmp_privileges2.status = _status;
      //this.status = response.status;
    },
    GetFinishCodes: async function () {
      var response = (
        await this.$http_in.get(`optmgr/v1/FinishCodes`)
      ).data;

      var _finish_codes = response.finish_codes.filter(e => !e.is_system).map((e) => {
        return {
          internal_name: e.finish_code,
          display_name: e.finish_code,
        };
      });
      this.tmp_privileges.finish_code = _finish_codes;
      this.tmp_privileges2.finish_code = _finish_codes;

      // this.privileges[0].children[1].children = _finish_codes;
    },

    GetProjects: async function () {
      var response = (
        await this.$http_in.get(`optmgr/v1/Project`)
      ).data;

      this.object_list.projects = response;

      for (const item of response) {

        this.project_names.set(item.internal_name, item.display_name);
      }
    },

    GetUsers: async function () {
      var response = (
        await this.$http_in.get(`optmgr/v1/User`)
      ).data;

      this.object_list.users = response;

    },



    GetDialPlans: async function () {
      var response = (
        await this.$http_in.get(`/smgr/v1/Classification`)
      ).data;

      var _dial_plans = response.map((e) => {
        return {
          internal_name: e._id,
          display_name: `${e.queue == 'undefined' ? globalThis._lang('t_undefined') : this.queue_names.get(e.queue)} - ${e.name}`,
        };
      });

      this.tmp_privileges.dial_plan = _dial_plans;
      this.tmp_privileges2.dial_plan = _dial_plans;

      // this.privileges[0].children[4].children = _dial_plans;
    },

    GetRoutes: async function () {
      var response = (
        await this.$http_in.get(`optmgr/v1/PermissionName`)
      ).data;

      var _routes = response.filter(e => e.key == 'menu' && !e.default).map((e) => {
        return {
          internal_name: e.value,
          display_name: globalThis._lang(e.display_name),
        };
      });
      this.tmp_privileges.menu = _routes;
      this.tmp_privileges2.menu = _routes;

      // var _chat = response.filter(e => e.key == 'chat').map((e) => {
      //   return {
      //     internal_name: e.value,
      //     display_name: globalThis._lang(e.display_name),
      //   };
      // });
      // this.tmp_privileges.chat = _chat;
      // this.tmp_privileges2.chat = _chat;

      var _crm = response.filter(e => e.key == 'crm').map((e) => {
        return {
          internal_name: e.value,
          display_name: globalThis._lang(e.display_name),
        };
      });
      this.tmp_privileges.crm = _crm;
      this.tmp_privileges2.crm = _crm;

      var _other = response.filter(e => e.key == 'other').map((e) => {
        return {
          internal_name: e.value,
          display_name: globalThis._lang(e.display_name),
        };
      });
      this.tmp_privileges.other = _other;
      this.tmp_privileges2.other = _other;

      // this.privileges[0].children[5].children = _routes;
    },

    GetQueues: async function () {
      var response = (
        await this.$http_in.get(`optmgr/v1/Queue`)
      ).data;

      this.object_list.queues = response;

      for (const item of response) {

        this.queue_names.set(item.internal_name, item.display_name);
      }

      var _queues = response.map((e) => {
        return {
          internal_name: e.internal_name,
          display_name: e.display_name,
        };
      });
      this.tmp_privileges.queue = _queues;
      this.tmp_privileges2.queue = _queues;

      // this.privileges[0].children[3].children = _queues;
      console.log(response);
    },
    GetLines: async function () {
      var response = (
        await this.$http_in.get(`optmgr/v1/Line`)
      ).data;


      var _lines = response.map((e) => {
        return {
          internal_name: e.caller_number,
          display_name: e.display_name,
        };
      });
      this.tmp_privileges.lines = _lines;
      this.tmp_privileges2.lines = _lines;

      // this.privileges[0].children[3].children = _queues;
      console.log(response);
    },
    GetIVR: async function () {

      var response = (
        await this.$http_in.get(`agent/v1/TransferTargets`)
      ).data;


      // this.transfer_targets = {
      //   Agent: response.LocalUsers,
      //   IVR: response.Ivr,
      //   vIVR: response.V_Ivr,
      //   pbIVR: response.PB_Ivr,
      // };


      var _ivr = [...response.Ivr, ...response.V_Ivr].map((e) => {
        return {
          internal_name: e.internal_name,
          display_name: e.display_name,
        };
      });
      this.tmp_privileges.ivr = _ivr;
      this.tmp_privileges2.ivr = _ivr;

      var _phone_book = response.PB_Ivr.map((e) => {
        return {
          internal_name: e.internal_name,
          display_name: e.display_name,
        };
      });
      this.tmp_privileges.phone_book = _phone_book;
      this.tmp_privileges2.phone_book = _phone_book;

      // this.privileges[0].children[3].children = _queues;
      console.log(response);
    },
    GetReports: async function () {
      var response = (
        await this.$http_in.get(`report/v1/Reports`)
      ).data;

      var _reports = response.map((e) => {
        return {
          internal_name: e.internal_name,
          display_name: globalThis._lang(e.display_name),
        };
      });
      this.tmp_privileges.report = _reports;
      this.tmp_privileges2.report = _reports;

      // this.privileges[0].children[3].children = _queues;
      console.log(response);
    },
    GetProfileObjects: async function () {
      var response = (
        await this.$http_in.get(`Auth/v1/ProfileObjects`, {
          headers: {
            authorization: globalThis.qm_token,
          },
        })
      ).data;

      this.temp_profile_objects.users = [];
      this.temp_profile_objects.queues = [];
      this.temp_profile_objects.projects = [];

      for (const item of response) {
        if (item.type == 'user') {
          this.temp_profile_objects.users.push(item);
        } else if (item.type == 'queue') {
          this.temp_profile_objects.queues.push(item);
        } else {
          this.temp_profile_objects.projects.push(item);
        }
      }
    },
    flip_bool: function (bool_name) {
      this[bool_name] = !this[bool_name];
    },
    create_UUID() {
      var dt = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
      });
      return uuid;
    },
  },
  mounted: async function () {
    globalThis.profiles = this;
    this.is_progress = true;
    // Promise.all(
    //   [
    //     this.GetQueues(),
    //     this.GetProjects(),
    //     this.GetUsers(),
    //     this.GetProfileObjects(),
    //     this.GetProfiles(),
    //     this.GetStatusAndCategories(),
    //     this.GetFinishCodes(),
    //     this.GetDialPlans(),
    //     this.GetRoutes(),
    //     this.GetReports(),
    //     this.reset_record(),
    //   ]

    // );
    await this.GetQueues(),
      await this.GetProjects(),
      await this.GetUsers(),
      this.GetProfileObjects(),
      this.GetProfiles(),
      this.GetStatusAndCategories(),
      this.GetFinishCodes(),
      this.GetDialPlans(),
      this.GetRoutes(),
      this.GetReports(),
      this.GetIVR(),
      this.GetLines(),
      this.reset_record(),
      this.is_progress = false;












  },

}
</script>

<style lang="scss">
// @import "@core/scss/vue/libs/vue-select.scss";

.vue-treeselect-helper-hide {
  display: none;
}

.vue-treeselect-helper-zoom-effect-off {
  -ms-transform: none !important;
  transform: none !important;
}

@keyframes vue-treeselect-animation-fade-in {
  0% {
    opacity: 0;
  }
}

@keyframes vue-treeselect-animation-bounce {
  0%,
  to {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes vue-treeselect-animation-rotate {
  to {
    transform: rotate(1turn);
  }
}

.vue-treeselect__multi-value-item--transition-enter-active,
.vue-treeselect__multi-value-item--transition-leave-active {
  transition-duration: 0.2s;
  transition-property: transform, opacity;
}

.vue-treeselect__multi-value-item--transition-enter-active {
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.vue-treeselect__multi-value-item--transition-leave-active {
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  position: absolute;
}

.vue-treeselect__multi-value-item--transition-enter,
.vue-treeselect__multi-value-item--transition-leave-to {
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
}

.vue-treeselect__multi-value-item--transition-move {
  transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.vue-treeselect {
  position: relative;
  text-align: left;
}

[dir="rtl"] .vue-treeselect {
  text-align: right;
}

.vue-treeselect div,
.vue-treeselect span {
  box-sizing: border-box;
}

.vue-treeselect svg {
  fill: currentColor;
}

.vue-treeselect__control {
  padding-left: 5px;
  padding-right: 5px;
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 36px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #fff;
  transition-duration: 0.2s;
  transition-property: border-color, box-shadow, width, height, background-color,
    opacity;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.vue-treeselect:not(.vue-treeselect--disabled):not(.vue-treeselect--focused)
  .vue-treeselect__control:hover {
  border-color: #cfcfcf;
}

.vue-treeselect--focused:not(.vue-treeselect--open) .vue-treeselect__control {
  border-color: #9ca0a4;

  box-shadow: 0 0 0 3px rgba(3, 155, 229, 0.1);
}

.vue-treeselect--disabled .vue-treeselect__control {
  background-color: #f9f9f9;
}

.vue-treeselect--open .vue-treeselect__control {
  border-color: #cfcfcf;
}

.vue-treeselect--open.vue-treeselect--open-below .vue-treeselect__control {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.vue-treeselect--open.vue-treeselect--open-above .vue-treeselect__control {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.vue-treeselect__multi-value,
.vue-treeselect__value-container {
  width: 100%;
  vertical-align: middle;
}

.vue-treeselect__value-container {
  display: table-cell;
  position: relative;
}

.vue-treeselect--searchable:not(.vue-treeselect--disabled)
  .vue-treeselect__value-container {
  cursor: text;
}

.vue-treeselect__multi-value {
  display: inline-block;
}

.vue-treeselect--has-value .vue-treeselect__multi-value {
  margin-bottom: 5px;
}

.vue-treeselect__placeholder,
.vue-treeselect__single-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  line-height: 34px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.vue-treeselect__placeholder {
  color: #bdbdbd;
}

.vue-treeselect__single-value {
  color: #333;
}

.vue-treeselect--focused.vue-treeselect--searchable
  .vue-treeselect__single-value {
  color: #bdbdbd;
}

.vue-treeselect--disabled .vue-treeselect__single-value {
  position: static;
}

.vue-treeselect__multi-value-item-container {
  display: inline-block;
  padding-top: 5px;
  padding-right: 5px;
  vertical-align: top;
}

[dir="rtl"] .vue-treeselect__multi-value-item-container {
  padding-right: 0;
  padding-left: 5px;
}

.vue-treeselect__multi-value-item {
  display: inline-table;
  padding: 2px 0;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 2px;
  font-size: 14px;
  font-family: "Montserrat", "OpenSans", Helvetica, Arial, serif;

  vertical-align: top;
}

.vue-treeselect:not(.vue-treeselect--disabled)
  .vue-treeselect__multi-value-item:not(
    .vue-treeselect__multi-value-item-disabled
  ):hover
  .vue-treeselect__multi-value-item:not(.vue-treeselect__multi-value-item-new)
  .vue-treeselect__multi-value-item:not(
    .vue-treeselect__multi-value-item-new
  ):hover,
.vue-treeselect__multi-value-item {
  cursor: pointer;
  background: #9ca0a4;
  color: #9ca0a4;
}

.vue-treeselect__multi-value-item.vue-treeselect__multi-value-item-disabled {
  cursor: default;
  background: #f5f5f5;
  color: #757575;
}

.vue-treeselect--disabled .vue-treeselect__multi-value-item {
  cursor: default;
  background: #fff;
  border-color: #e5e5e5;
  color: #555;
}

.vue-treeselect__multi-value-item.vue-treeselect__multi-value-item-new,
.vue-treeselect__multi-value-item.vue-treeselect__multi-value-item-new:hover {
  background: #e8f5e9;
}

.vue-treeselect__multi-value-label {
  color: #fff;
}

.vue-treeselect__value-remove {
  display: table-cell;
  padding: 0 5px;
  vertical-align: middle;
}

.vue-treeselect__value-remove {
  color: #fff;

  padding-left: 5px;
  border-left: 0px solid #fff;
  line-height: 0;
}

[dir="rtl"] .vue-treeselect__value-remove {
  border-left: 0;
  border-right: 1px solid #fff;
}

.vue-treeselect__multi-value-item:hover .vue-treeselect__value-remove {
  color: #fff;
  color: #fff;
}

.vue-treeselect--disabled .vue-treeselect__value-remove,
.vue-treeselect__multi-value-item-disabled .vue-treeselect__value-remove {
  display: none;
}

.vue-treeselect__value-remove > svg {
  width: 6px;
  height: 6px;
}

.vue-treeselect__multi-value-label {
  padding-right: 5px;
  white-space: pre-line;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.vue-treeselect__limit-tip {
  display: inline-block;
  padding-top: 5px;
  padding-right: 5px;
  vertical-align: top;
}

[dir="rtl"] .vue-treeselect__limit-tip {
  padding-right: 0;
  padding-left: 5px;
}

.vue-treeselect__limit-tip-text {
  cursor: default;
  display: block;
  margin: 2px 0;
  padding: 1px 0;
  color: #bdbdbd;
  font-size: 14px;
  font-family: "Montserrat", "OpenSans", Helvetica, Arial, serif;

  font-weight: 600;
}

.vue-treeselect__input-container {
  display: block;
  max-width: 100%;
  outline: none;
}

.vue-treeselect--single .vue-treeselect__input-container {
  font-size: inherit;
  height: 100%;
}

.vue-treeselect--multi .vue-treeselect__input-container {
  display: inline-block;
  font-size: 14px;
  font-family: "Montserrat", "OpenSans", Helvetica, Arial, serif;

  vertical-align: top;
}

.vue-treeselect--searchable .vue-treeselect__input-container {
  padding-left: 5px;
  padding-right: 5px;
}

.vue-treeselect--searchable.vue-treeselect--multi.vue-treeselect--has-value
  .vue-treeselect__input-container {
  padding-top: 5px;
  padding-left: 0;
}

[dir="rtl"]
  .vue-treeselect--searchable.vue-treeselect--multi.vue-treeselect--has-value
  .vue-treeselect__input-container {
  padding-left: 5px;
  padding-right: 0;
}

.vue-treeselect--disabled .vue-treeselect__input-container {
  display: none;
}

.vue-treeselect__input,
.vue-treeselect__sizer {
  margin: 0;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;
}

.vue-treeselect__input {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  box-sizing: content-box;
  box-shadow: none;
  background: none rgba(0, 0, 0, 0);
  line-height: 1;
  vertical-align: middle;
}

.vue-treeselect__input::-ms-clear {
  display: none;
}

.vue-treeselect--single .vue-treeselect__input {
  width: 100%;
  height: 100%;
}

.vue-treeselect--multi .vue-treeselect__input {
  padding-top: 3px;
  padding-bottom: 3px;
}

.vue-treeselect--has-value .vue-treeselect__input {
  line-height: inherit;
  vertical-align: top;
}

.vue-treeselect__sizer {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  height: 0;
  overflow: scroll;
  white-space: pre;
}

.vue-treeselect__x-container {
  display: table-cell;
  vertical-align: middle;
  width: 20px;
  text-align: center;
  line-height: 0;
  cursor: pointer;
  color: #ccc;
  animation: vue-treeselect-animation-fade-in 0.2s
    cubic-bezier(0.075, 0.82, 0.165, 1);
}

.vue-treeselect__x-container:hover {
  color: #e53935;
  color: #fff;
}

.vue-treeselect__x {
  width: 8px;
  height: 8px;
}

.vue-treeselect__control-arrow-container {
  display: table-cell;
  vertical-align: middle;
  width: 20px;
  text-align: center;
  line-height: 0;
  cursor: pointer;
}

.vue-treeselect--disabled .vue-treeselect__control-arrow-container {
  cursor: default;
}

.vue-treeselect__control-arrow {
  width: 9px;
  height: 9px;
  color: #ccc;
}

.vue-treeselect:not(.vue-treeselect--disabled)
  .vue-treeselect__control-arrow-container:hover
  .vue-treeselect__control-arrow {
  color: #616161;
}

.vue-treeselect--disabled .vue-treeselect__control-arrow {
  opacity: 0.35;
}

.vue-treeselect__control-arrow--rotated {
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.vue-treeselect__menu-container {
  position: absolute;
  left: 0;
  width: 100%;
  overflow: visible;
  transition: 0s;
}

.vue-treeselect--open-below:not(.vue-treeselect--append-to-body)
  .vue-treeselect__menu-container {
  top: 100%;
}

.vue-treeselect--open-above:not(.vue-treeselect--append-to-body)
  .vue-treeselect__menu-container {
  bottom: 100%;
}

.vue-treeselect__menu {
  cursor: default;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  width: auto;
  border: 1px solid #cfcfcf;
  background: #fff;
  line-height: 180%;
  -webkit-overflow-scrolling: touch;
}

.vue-treeselect--open-below .vue-treeselect__menu {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  top: 0;
  margin-top: -1px;
  border-top-color: #f2f2f2;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.vue-treeselect--open-above .vue-treeselect__menu {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  bottom: 0;
  margin-bottom: -1px;
  border-bottom-color: #f2f2f2;
}

.vue-treeselect__indent-level-0 .vue-treeselect__option {
  padding-left: 5px;
}

[dir="rtl"] .vue-treeselect__indent-level-0 .vue-treeselect__option {
  padding-left: 5px;
  padding-right: 5px;
}

.vue-treeselect__indent-level-0 .vue-treeselect__tip {
  padding-left: 25px;
}

[dir="rtl"] .vue-treeselect__indent-level-0 .vue-treeselect__tip {
  padding-left: 5px;
  padding-right: 25px;
}

.vue-treeselect__indent-level-1 .vue-treeselect__option {
  padding-left: 25px;
}

[dir="rtl"] .vue-treeselect__indent-level-1 .vue-treeselect__option {
  padding-left: 5px;
  padding-right: 25px;
}

.vue-treeselect__indent-level-1 .vue-treeselect__tip {
  padding-left: 45px;
}

[dir="rtl"] .vue-treeselect__indent-level-1 .vue-treeselect__tip {
  padding-left: 5px;
  padding-right: 45px;
}

.vue-treeselect__indent-level-2 .vue-treeselect__option {
  padding-left: 45px;
}

[dir="rtl"] .vue-treeselect__indent-level-2 .vue-treeselect__option {
  padding-left: 5px;
  padding-right: 45px;
}

.vue-treeselect__indent-level-2 .vue-treeselect__tip {
  padding-left: 65px;
}

[dir="rtl"] .vue-treeselect__indent-level-2 .vue-treeselect__tip {
  padding-left: 5px;
  padding-right: 65px;
}

.vue-treeselect__indent-level-3 .vue-treeselect__option {
  padding-left: 65px;
}

[dir="rtl"] .vue-treeselect__indent-level-3 .vue-treeselect__option {
  padding-left: 5px;
  padding-right: 65px;
}

.vue-treeselect__indent-level-3 .vue-treeselect__tip {
  padding-left: 85px;
}

[dir="rtl"] .vue-treeselect__indent-level-3 .vue-treeselect__tip {
  padding-left: 5px;
  padding-right: 85px;
}

.vue-treeselect__indent-level-4 .vue-treeselect__option {
  padding-left: 85px;
}

[dir="rtl"] .vue-treeselect__indent-level-4 .vue-treeselect__option {
  padding-left: 5px;
  padding-right: 85px;
}

.vue-treeselect__indent-level-4 .vue-treeselect__tip {
  padding-left: 105px;
}

[dir="rtl"] .vue-treeselect__indent-level-4 .vue-treeselect__tip {
  padding-left: 5px;
  padding-right: 105px;
}

.vue-treeselect__indent-level-5 .vue-treeselect__option {
  padding-left: 105px;
}

[dir="rtl"] .vue-treeselect__indent-level-5 .vue-treeselect__option {
  padding-left: 5px;
  padding-right: 105px;
}

.vue-treeselect__indent-level-5 .vue-treeselect__tip {
  padding-left: 125px;
}

[dir="rtl"] .vue-treeselect__indent-level-5 .vue-treeselect__tip {
  padding-left: 5px;
  padding-right: 125px;
}

.vue-treeselect__indent-level-6 .vue-treeselect__option {
  padding-left: 125px;
}

[dir="rtl"] .vue-treeselect__indent-level-6 .vue-treeselect__option {
  padding-left: 5px;
  padding-right: 125px;
}

.vue-treeselect__indent-level-6 .vue-treeselect__tip {
  padding-left: 145px;
}

[dir="rtl"] .vue-treeselect__indent-level-6 .vue-treeselect__tip {
  padding-left: 5px;
  padding-right: 145px;
}

.vue-treeselect__indent-level-7 .vue-treeselect__option {
  padding-left: 145px;
}

[dir="rtl"] .vue-treeselect__indent-level-7 .vue-treeselect__option {
  padding-left: 5px;
  padding-right: 145px;
}

.vue-treeselect__indent-level-7 .vue-treeselect__tip {
  padding-left: 165px;
}

[dir="rtl"] .vue-treeselect__indent-level-7 .vue-treeselect__tip {
  padding-left: 5px;
  padding-right: 165px;
}

.vue-treeselect__indent-level-8 .vue-treeselect__option {
  padding-left: 165px;
}

[dir="rtl"] .vue-treeselect__indent-level-8 .vue-treeselect__option {
  padding-left: 5px;
  padding-right: 165px;
}

.vue-treeselect__indent-level-8 .vue-treeselect__tip {
  padding-left: 185px;
}

[dir="rtl"] .vue-treeselect__indent-level-8 .vue-treeselect__tip {
  padding-left: 5px;
  padding-right: 185px;
}

.vue-treeselect__option {
  padding-left: 5px;
  padding-right: 5px;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.vue-treeselect__option--highlight {
  background: #f5f5f5;
}

.vue-treeselect--single .vue-treeselect__option--selected {
  background: #9ca0a4;
  font-weight: 600;
}

.vue-treeselect--single .vue-treeselect__option--selected:hover {
  background: #9ca0a4;
}

.vue-treeselect__option--hide {
  display: none;
}

.vue-treeselect__option-arrow-container,
.vue-treeselect__option-arrow-placeholder {
  display: table-cell;
  vertical-align: middle;
  width: 20px;
  text-align: center;
  line-height: 0;
}

.vue-treeselect__option-arrow-container {
  cursor: pointer;
}

.vue-treeselect__option-arrow {
  display: inline-block;
  width: 9px;
  height: 9px;
  color: #ccc;
  vertical-align: middle;
  transition: transform 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

[dir="rtl"] .vue-treeselect__option-arrow {
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.vue-treeselect--branch-nodes-disabled
  .vue-treeselect__option:hover
  .vue-treeselect__option-arrow,
.vue-treeselect__option-arrow-container:hover .vue-treeselect__option-arrow {
  color: #616161;
}

.vue-treeselect__option-arrow--rotated,
[dir="rtl"] .vue-treeselect__option-arrow--rotated {
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.vue-treeselect__option-arrow--rotated.vue-treeselect__option-arrow--prepare-enter {
  -ms-transform: rotate(-90deg) !important;
  transform: rotate(-90deg) !important;
}

[dir="rtl"]
  .vue-treeselect__option-arrow--rotated.vue-treeselect__option-arrow--prepare-enter {
  -ms-transform: rotate(90deg) !important;
  transform: rotate(90deg) !important;
}

.vue-treeselect__label-container {
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
  display: table;
  width: 100%;
  table-layout: fixed;
  color: inherit;
}

.vue-treeselect__option--disabled .vue-treeselect__label-container {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}

.vue-treeselect__checkbox-container {
  display: table-cell;
  width: 20px;
  min-width: 20px;
  height: 100%;
  text-align: center;
  vertical-align: middle;
}

.vue-treeselect__checkbox {
  display: block;
  margin: auto;
  width: 12px;
  height: 12px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  position: relative;
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.vue-treeselect__check-mark,
.vue-treeselect__minus-mark {
  display: block;
  position: absolute;
  left: 1px;
  top: 1px;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all 0.2s ease;
}

.vue-treeselect__minus-mark {
  width: 8px;
  height: 8px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAgMAAAC5YVYYAAAACVBMVEUAAAD///////9zeKVjAAAAAnRSTlMAuLMp9oYAAAAPSURBVAjXY4CDrJUgBAMAGaECJ9dz3BAAAAAASUVORK5CYII=);
  background-size: 8px 8px;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .vue-treeselect__minus-mark {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAgMAAABinRfyAAAADFBMVEUAAAD///////////84wDuoAAAAA3RSTlMAyTzPIdReAAAAGUlEQVQI12PAD+b///+Nof7//79gAsLFCwAx/w4blADeeQAAAABJRU5ErkJggg==);
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .vue-treeselect__minus-mark {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAgMAAABinRfyAAAADFBMVEUAAAD///////////84wDuoAAAAA3RSTlMAyTzPIdReAAAAGUlEQVQI12PAD+b///+Nof7//79gAsLFCwAx/w4blADeeQAAAABJRU5ErkJggg==);
  }
}

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
  .vue-treeselect__minus-mark {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAD1BMVEUAAAD///////////////+PQt5oAAAABHRSTlMAy2EFIuWxUgAAACRJREFUGNNjGBBgJOICBY7KDCoucODEAJSAS6FwUJShGjAQAADBPRGrK2/FhgAAAABJRU5ErkJggg==);
  }
}

.vue-treeselect__checkbox--indeterminate > .vue-treeselect__minus-mark {
  opacity: 1;
}

.vue-treeselect__checkbox--disabled .vue-treeselect__minus-mark {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAgMAAAC5YVYYAAAACVBMVEUAAADi4uLh4eHOxeSRAAAAAnRSTlMAuLMp9oYAAAAPSURBVAjXY4CDrJUgBAMAGaECJ9dz3BAAAAAASUVORK5CYII=);
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .vue-treeselect__checkbox--disabled .vue-treeselect__minus-mark {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAgMAAABinRfyAAAADFBMVEUAAADi4uLi4uLh4eE5RQaIAAAAA3RSTlMAyTzPIdReAAAAGUlEQVQI12PAD+b///+Nof7//79gAsLFCwAx/w4blADeeQAAAABJRU5ErkJggg==);
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .vue-treeselect__checkbox--disabled .vue-treeselect__minus-mark {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAgMAAABinRfyAAAADFBMVEUAAADi4uLi4uLh4eE5RQaIAAAAA3RSTlMAyTzPIdReAAAAGUlEQVQI12PAD+b///+Nof7//79gAsLFCwAx/w4blADeeQAAAABJRU5ErkJggg==);
  }
}

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
  .vue-treeselect__checkbox--disabled .vue-treeselect__minus-mark {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAD1BMVEUAAADh4eHg4ODNzc3h4eEYfw2wAAAABHRSTlMAy2EFIuWxUgAAACRJREFUGNNjGBBgJOICBY7KDCoucODEAJSAS6FwUJShGjAQAADBPRGrK2/FhgAAAABJRU5ErkJggg==);
  }
}

.vue-treeselect__check-mark {
  width: 8px;
  height: 8px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAMAAADz0U65AAAAQlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////8IX9KGAAAAFXRSTlMA8u24NxILB+Tawb6jiH1zRz0xIQIIP3GUAAAAMklEQVQI1y3FtQEAMQDDQD+EGbz/qkEVOpyEOP6PudKjZNSXn4Jm2CKRdBKzSLsFWl8fMG0Bl6Jk1rMAAAAASUVORK5CYII=);
  background-size: 8px 8px;
  -ms-transform: scaleY(0.125);
  transform: scaleY(0.125);
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .vue-treeselect__check-mark {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAYFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////98JRy6AAAAH3RSTlMAzu4sDenl38fBvo1OMyIdEQrj1cSihX5hYFpHNycIcQOASAAAAF9JREFUGNN9zEcOgDAMRFHTS0LvNfe/JRmHKAIJ/mqeLJn+k9uDtaeUeFnFziGsBucUTirrprfe81RqZ3Bb6hPWeuZwDFOHyf+ig9CCzQ7INBn7bG5kF+QSt13BHNJnF7AaCT4Y+CW7AAAAAElFTkSuQmCC);
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .vue-treeselect__check-mark {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAYFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////98JRy6AAAAH3RSTlMAzu4sDenl38fBvo1OMyIdEQrj1cSihX5hYFpHNycIcQOASAAAAF9JREFUGNN9zEcOgDAMRFHTS0LvNfe/JRmHKAIJ/mqeLJn+k9uDtaeUeFnFziGsBucUTirrprfe81RqZ3Bb6hPWeuZwDFOHyf+ig9CCzQ7INBn7bG5kF+QSt13BHNJnF7AaCT4Y+CW7AAAAAElFTkSuQmCC);
  }
}

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
  .vue-treeselect__check-mark {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAWlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////9ZMre9AAAAHXRSTlMA/PiJhGNI9XlEHJB/b2ldV08+Oibk49vPp6QhAYgGBuwAAACCSURBVCjPrdHdDoIwDAXgTWAqCigo/+f9X5OwnoUwtis4V92XNWladUl+rzQPeQJAN2EHxoOnsPn7/oYk8fxBv08Rr/deOH/aZ2Nm8ZJ+s573QGfWKnNuZGzWm3+lv2V3pcU1XQ385/yjmBoM3Z+dXvlbYLLD3ujhTaOM3KaIXvNkFkuSEvYy1LqOAAAAAElFTkSuQmCC);
  }
}

.vue-treeselect__checkbox--checked > .vue-treeselect__check-mark {
  opacity: 1;
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}

.vue-treeselect__checkbox--disabled .vue-treeselect__check-mark {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAMAAADz0U65AAAAP1BMVEUAAADj4+Pf39/h4eHh4eHh4eHk5OTh4eHg4ODi4uLh4eHh4eHg4ODh4eHh4eHg4ODh4eHh4eHp6en////h4eFqcyvUAAAAFHRSTlMAOQfy7bgS5NrBvqOIfXNHMSELAgQ/iFsAAAA2SURBVAjXY4AANjYIzcjMAaVFuBkY+RkEWERYmRjYRXjANAOfiIgIFxNIAa8IpxBEi6AwiAQAK2MBd7xY8csAAAAASUVORK5CYII=);
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .vue-treeselect__checkbox--disabled .vue-treeselect__check-mark {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAXVBMVEUAAADh4eHh4eHh4eHi4uLb29vh4eHh4eHh4eHh4eHh4eHh4eHh4eHi4uLi4uLj4+Pi4uLk5OTo6Ojh4eHh4eHi4uLg4ODg4ODh4eHg4ODh4eHf39/g4OD////h4eEzIk+wAAAAHnRSTlMAzu6/LA3p5eLZx8ONTjYiHRIKooV+YWBaRzEnCANnm5rnAAAAZElEQVQY033P2wqAIAyA4VWaaWrnc/n+j5mbhBjUf7WPoTD47TJb4i5zTr/sRDRHuyFaoWX7uK/RlbctlPEuyI1f4WY9yQINEkf6rzzo8YIzmUFoCs7J1EjeIaa9bXIEmzl8dgOZEAj/+2IvzAAAAABJRU5ErkJggg==);
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .vue-treeselect__checkbox--disabled .vue-treeselect__check-mark {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAXVBMVEUAAADh4eHh4eHh4eHi4uLb29vh4eHh4eHh4eHh4eHh4eHh4eHh4eHi4uLi4uLj4+Pi4uLk5OTo6Ojh4eHh4eHi4uLg4ODg4ODh4eHg4ODh4eHf39/g4OD////h4eEzIk+wAAAAHnRSTlMAzu6/LA3p5eLZx8ONTjYiHRIKooV+YWBaRzEnCANnm5rnAAAAZElEQVQY033P2wqAIAyA4VWaaWrnc/n+j5mbhBjUf7WPoTD47TJb4i5zTr/sRDRHuyFaoWX7uK/RlbctlPEuyI1f4WY9yQINEkf6rzzo8YIzmUFoCs7J1EjeIaa9bXIEmzl8dgOZEAj/+2IvzAAAAABJRU5ErkJggg==);
  }
}

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
  .vue-treeselect__checkbox--disabled .vue-treeselect__check-mark {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAUVBMVEUAAADh4eHh4eHh4eHh4eHi4uLi4uLh4eHh4eHh4eHf39/j4+Ph4eHh4eHh4eHg4ODi4uLh4eHh4eHi4uLh4eHh4eHh4eHh4eHh4eH////h4eF3FMFTAAAAGnRSTlMA+/eJhGhfSHE9JBzz5KaQf3pXT0Xbz0I5AYDw8F0AAAB+SURBVCjPrdHbDoMgEEVRKAii1dZe9fz/hxplTiKIT7qfYCWTEEZdUvOwbckNAD2WHeh3brHW5f5EzGQ+iN+b1Gt6KPvtv16Dn6JX9M9ya3/A1yfu5dlyduL6Hec7mXY6ddXLPP2lpABGZ8PWXfYLTJxZekVhhl7eTX24zZPNKXoRC7zQLjUAAAAASUVORK5CYII=);
  }
}

.vue-treeselect__checkbox--unchecked {
  border-color: #e0e0e0;
  background: #fff;
}

.vue-treeselect__label-container:hover .vue-treeselect__checkbox--unchecked {
  border-color: #9ca0a4;

  background: #fff;
  font-size: 14px;
  font-family: "Montserrat", "OpenSans", Helvetica, Arial, serif;
}

.vue-treeselect__checkbox--checked,
.vue-treeselect__checkbox--indeterminate,
.vue-treeselect__label-container:hover .vue-treeselect__checkbox--checked,
.vue-treeselect__label-container:hover
  .vue-treeselect__checkbox--indeterminate {
  border-color: #9ca0a4;
  background: #9ca0a4;
}

.vue-treeselect__checkbox--disabled,
.vue-treeselect__label-container:hover .vue-treeselect__checkbox--disabled {
  border-color: #e0e0e0;
  background-color: #f7f7f7;
}

.vue-treeselect__label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: table-cell;
  padding-left: 5px;
  max-width: 100%;
  vertical-align: middle;
  cursor: inherit;
  font-size: 14px;
  font-family: "Montserrat", "OpenSans", Helvetica, Arial, serif;
}

[dir="rtl"] .vue-treeselect__label {
  padding-left: 0;
  padding-right: 5px;
}

.vue-treeselect__count {
  margin-left: 5px;
  font-weight: 400;
  opacity: 0.6;
}

[dir="rtl"] .vue-treeselect__count {
  margin-left: 0;
  margin-right: 5px;
}

.vue-treeselect__tip {
  padding-left: 5px;
  padding-right: 5px;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: #757575;
}

.vue-treeselect__tip-text {
  display: table-cell;
  vertical-align: middle;
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
  font-family: "Montserrat", "OpenSans", Helvetica, Arial, serif;
}

.vue-treeselect__error-tip .vue-treeselect__retry {
  cursor: pointer;
  margin-left: 5px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  color: #fff;
}

[dir="rtl"] .vue-treeselect__error-tip .vue-treeselect__retry {
  margin-left: 0;
  margin-right: 5px;
}

.vue-treeselect__icon-container {
  display: table-cell;
  vertical-align: middle;
  width: 20px;
  text-align: center;
  line-height: 0;
}

.vue-treeselect--single .vue-treeselect__icon-container {
  padding-left: 5px;
}

[dir="rtl"] .vue-treeselect--single .vue-treeselect__icon-container {
  padding-left: 0;
  padding-right: 5px;
}

.vue-treeselect__icon-warning {
  display: block;
  margin: auto;
  border-radius: 50%;
  position: relative;
  width: 12px;
  height: 12px;
  background: #fb8c00;
}

.vue-treeselect__icon-warning:after {
  display: block;
  position: absolute;
  content: "";
  left: 5px;
  top: 2.5px;
  width: 2px;
  height: 1px;
  border-color: #fff;
  border-style: solid;
  border-width: 5px 0 1px;
}

.vue-treeselect__icon-error {
  display: block;
  margin: auto;
  border-radius: 50%;
  position: relative;
  width: 12px;
  height: 12px;

  background: #e53935;
  background: #fff;
}

.vue-treeselect__icon-error:after,
.vue-treeselect__icon-error:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.vue-treeselect__icon-error:before {
  width: 6px;
  height: 2px;
  left: 3px;
  top: 5px;
}

.vue-treeselect__icon-error:after {
  width: 2px;
  height: 6px;
  left: 5px;
  top: 3px;
}

.vue-treeselect__icon-loader {
  display: block;
  margin: auto;
  position: relative;
  width: 12px;
  height: 12px;
  text-align: center;
  animation: vue-treeselect-animation-rotate 1.6s linear infinite;
}

.vue-treeselect__icon-loader:after,
.vue-treeselect__icon-loader:before {
  border-radius: 50%;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  animation: vue-treeselect-animation-bounce 1.6s ease-in-out infinite;
}

.vue-treeselect__icon-loader:before {
  background: #9ca0a4;
}

.vue-treeselect__icon-loader:after {
  background: #b3e5fc;
  animation-delay: -0.8s;
}

.vue-treeselect__menu-placeholder {
  display: none;
}

.vue-treeselect__portal-target {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  overflow: visible;
  box-sizing: border-box;
}
</style>
