<template>
  <div>
    <b-tabs
      lazy
      pills
      active-nav-item-class="bg-light-primary border-0 shadow-none cursor-pointer"
    >
      <b-tab
        active
        v-if="menu.includes('projects')"
        :title="lang('t_projects')"
      >
        <projects></projects>
      </b-tab>
      <b-tab v-if="menu.includes('queues')" :title="lang('t_queues')">
        <queues></queues>
      </b-tab>
      <!-- <b-tab  :title="lang('t_queueGroup')">
        <queue-group></queue-group>
      </b-tab> -->
      <b-tab v-if="menu.includes('users')" :title="lang('t_users')">
        <users></users>
      </b-tab>
      <b-tab v-if="menu.includes('profiles')" :title="lang('t_profiles')">
        <profiles></profiles>
      </b-tab>
      <b-tab v-if="menu.includes('statuses')" :title="lang('t_statuses')">
        <statuses></statuses>
      </b-tab>
      <b-tab
        v-if="menu.includes('finish_codes')"
        :title="lang('t_finishCodes')"
      >
        <finish-codes></finish-codes>
      </b-tab>
      <!-- v-if="menu.includes('quality_form')" -->

      <b-tab :title="lang('t_qualityForm')">
        <quality-form></quality-form>
      </b-tab>
      <b-tab v-if="menu.includes('routers')" :title="lang('t_extensionRoutes')">
        <routers></routers>
      </b-tab>

      <b-tab v-if="menu.includes('black_list')" :title="lang('t_blackList')">
        <black-list></black-list>
      </b-tab>
      <b-tab v-if="menu.includes('shifts')" :title="lang('t_shifts')">
        <shifts></shifts>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BContainer, BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BLink, BButton, BTabs, BTab } from 'bootstrap-vue'
// import SmsSender from './SmsSender.vue'
import Users from './Users/list/List.vue'
import Queues from './Queues/list/List.vue'
import Projects from './Projects/list/List.vue'
import Routers from './Routers/list/List.vue'
import BlackList from './BlackList/list/List.vue'
import Shifts from './Shifts/list/List.vue'
import FinishCodes from './FinishCodes/Main.vue'
import Statuses from './Statuses/Main.vue'
import Profiles from './Profiles/Main.vue'
import QueueGroup from './QueueGroup/QueueGroup.vue'
import QualityForm from './QualityForm/list/List.vue'

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      selectedButton: '',
      optionsButton: [
        { text: globalThis._lang("t_users"), value: 'users' },
        { text: globalThis._lang("t_queues"), value: 'queues' },
        { text: globalThis._lang("t_projects"), value: 'projects' },
        // { text: 'Ayarlar', value: 'settings' },
      ],
      menu: [],
    }
  },
  components: {
    QualityForm,
    BContainer,
    Profiles,
    Statuses,
    FinishCodes,
    BlackList,
    Shifts,
    QueueGroup,
    Routers,
    Users,
    Queues,
    Projects,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
    BTabs,
    BTab,
  },
  mounted: async function () {
    this.menu = globalThis.permissions["menu"];
    if (this.menu.includes('users')) {
      this.selectedButton = 'users';
    }
  }
}
</script>

<style>
</style>
